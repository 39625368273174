import type { ActionFunctionArgs, LoaderFunctionArgs } from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import { Form, useLoaderData } from "@remix-run/react";
import Layout from "~/components/Layout";
import { validateCredentials } from "~/lib/auth.server";
import { db } from "~/lib/db.server";
import log from "~/lib/log";
import { commitSession, getSession } from "~/lib/session.server";

export async function loader({ request }: LoaderFunctionArgs) {
  const session = await getSession(request.headers.get("Cookie"));

  const userId = session.get("userId");
  if (userId) {
    const user = await db.user.findFirst({ where: { id: userId } });
    if (user) {
      return redirect("/");
    }
  }

  const data = { error: session.get("error") };
  return json(data, {
    headers: {
      "Set-Cookie": await commitSession(session),
    },
  });
}

export async function action({ request }: ActionFunctionArgs) {
  const session = await getSession(request.headers.get("Cookie"));
  const form = await request.formData();
  const username = form.get("username") as string;
  const password = form.get("password") as string;

  const userId = await validateCredentials(username, password);

  if (userId == null) {
    session.flash("error", "שם משתמש או סיסמא שגויים");

    // Redirect back to the login page with errors.
    log.debug("invalid username or password. commit session with error");
    return redirect("/login", {
      headers: {
        "Set-Cookie": await commitSession(session),
      },
    });
  }

  session.set("userId", userId);

  // Login succeeded, send them to the home page.
  log.debug("login succeed. redirect to home");
  return redirect("/", {
    headers: {
      "Set-Cookie": await commitSession(session),
    },
  });
}

export default function Login() {
  const data = useLoaderData<typeof loader>();
  return (
    <Layout>
      <Form method="POST" className="w-[300px] text-center">
        <span className="text-4xl">התחברות</span>
        <div className="form-control w-full max-w-xs">
          <label className="label">
            <span className="label-text">שם משתמש</span>
          </label>
          <input
            type="text"
            name="username"
            className="input input-bordered w-full max-w-xs"
          />
        </div>
        <div className="form-control w-full max-w-xs">
          <label className="label">
            <span className="label-text">סיסמא</span>
          </label>
          <input
            type="password"
            name="password"
            className="input input-bordered w-full max-w-xs"
          />
        </div>
        <button type="submit" className="btn btn-primary mt-5 w-full">
          התחבר
        </button>
        {data?.error && <p className="text-error mt-2">{data.error}</p>}
      </Form>
    </Layout>
  );
}
